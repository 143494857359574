import React, { lazy } from 'react';
import { Container } from 'rsuite';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';


import './App.scss';
// be sure to overwrite
import 'rsuite/dist/rsuite.min.css';
import './rsuite-override.scss';

import './plugins';
import { AuthenticationProvider } from './providers/authentication';
import {
  ImportPage,
  ProjectPage,
  ProjectFormPage,
  DesignerPage,
  DashboardPage,
  LoginPage,
  SettingsPage,
  PaymentPage,
  MessagePage,
  GitHubLandingPage,
  PrivacyPage,
  EmptyPage,
  TermsPage,
  PlaygroundPage,
  PlaygroundConfiguratorPage,
  TestPage
} from './pages';
import { AppolloDataLayer } from './providers/apollo';
import { AnalyticsProvider } from './providers/analytics';
import { ModalProvider } from './components/modal';
import { CookieBanner } from './components/cookie-banner';

const PlayWrightPage = lazy(() => import('./pages/playwright'));
const BuilderPage = lazy(() => import('./pages/builder-iframe'));
const PublishedFormRSuite5Page = lazy(() => import('./pages/widget/widget-rsuite5'));
const PublishedFormAntdPage = lazy(() => import('./pages/widget/widget-antd'));
const PublishedFormMantinePage = lazy(() => import('./pages/widget/widget-mantine'));
const PublishedFormReactPage = lazy(() => import('./pages/widget/widget-react'));
const PublishedFormBootstrapPage = lazy(() => import('./pages/widget/widget-bootstrap'));
const PublishedFormMUIPage = lazy(() => import('./pages/widget/widget-material-ui'));
const DemoPage = lazy(() => import('./pages/demo'));
const BuilderPlaygroundPage = lazy(() => import('./pages/builder-playground'));

function App() {
  const designerPage = <DesignerPage />;
  const settingsPage = <SettingsPage />;
  const playgroundPage = <PlaygroundConfiguratorPage />;

  let pages = {
    'default': [
      <Route path="/" element={designerPage} />,
      <Route path="/w/:formHash/react-rsuite5" element={<PublishedFormRSuite5Page/>} />,
      <Route path="/w/:formHash/react-antd" element={<PublishedFormAntdPage/>} />,
      <Route path="/w/:formHash/react-mantine" element={<PublishedFormMantinePage/>} />,
      <Route path="/w/:formHash/react" element={<PublishedFormReactPage/>} />,
      <Route path="/w/:formHash/react-bootstrap" element={<PublishedFormBootstrapPage/>} />,
      <Route path="/w/:formHash/react-material-ui" element={<PublishedFormMUIPage/>} />,
      <Route path="/f/:formHash" element={designerPage} />,
      <Route path="/p/:formHash" element={<PlaygroundPage/>} />
    ],
    'localhost,designer.letsform.dev': [
      <Route path="/import" element={<ImportPage />} />,
      <Route path="/projects/:projectId" element={<ProjectPage />} />,
      <Route path="/projects/:projectId/forms/:formId" element={<ProjectFormPage />} />,
      <Route path="/projects/:projectId/new" element={<ProjectFormPage />} />,
      <Route path="/payments/:paymentId" element={<PaymentPage />}/>,
      <Route path="/dashboard" element={<DashboardPage />} />,
      <Route path="/login" element={<LoginPage />} />,
      <Route path="/settings" element={settingsPage}/>,
      <Route path="/settings/:section" element={settingsPage}/>,
      <Route path="/message/:code" element={<MessagePage />}/>,
      <Route path="/github" element={<GitHubLandingPage />}/>,
      <Route path="/privacy" element={<PrivacyPage />}/>,
      <Route path="/terms" element={<TermsPage />}/>,
      <Route path="/playground/new" element={playgroundPage} />,
      <Route path="/playground/:shareCode" element={playgroundPage} />,
      <Route path="/test" element={<TestPage />} />,
      <Route path="/demo" element={<DemoPage />} />,
      <Route path="/playwright/:section" element={<PlayWrightPage />} />,
      <Route path="/builder-playground/:builderHash" element={<BuilderPlaygroundPage />} />
    ],
    'localhost,builder.letsforn.dev': [
      <Route path="/builder/:builderHash" element={<BuilderPage />} />
    ]
  };

  return (
    <AuthenticationProvider>
      <AnalyticsProvider>
        <AppolloDataLayer>
          <ModalProvider>
            <Container>
              <BrowserRouter>
                <Routes>
                  {pages.default}
                  {Object
                    .keys(pages)
                    .filter(p => p !== 'default')
                    .reduce(
                      (acc, domains) => domains.split(',').includes(window.location.hostname) ?
                        [...acc, pages[domains]] : acc,
                      []
                    )
                  }
                  <Route path="*" element={<EmptyPage />} />
                </Routes>
              </BrowserRouter>
              <CookieBanner />
            </Container>
          </ModalProvider>
        </AppolloDataLayer>
      </AnalyticsProvider>
    </AuthenticationProvider>
  );
};

export default App;