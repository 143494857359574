import React, { useCallback } from 'react';

import { useFormBuilderStore } from '../../../pages/builder/state';
import { EmptyPlaceholder } from '../../../components/empty-placeholder';
import { replaceField } from '../../../components/lets-form/index';
import { lfWarn } from '../../../components/lets-form/helpers/lf-log';

import { FieldEditor } from './field-editor';


const EditFieldPanelContainer = () => {

  const form = useFormBuilderStore(state => state.form);
  const setForm = useFormBuilderStore(state => state.setForm);
  const framework = useFormBuilderStore(state => state.framework);
  const locale = useFormBuilderStore(state => state.locale);
  const field = useFormBuilderStore(state => state.field);
  const setField = useFormBuilderStore(state => state.setField);
  const sidebar = useFormBuilderStore(state => state.sidebar);
  const setJsErrors = useFormBuilderStore(state => state.setJsErrors);

  const handleReplaceField = useCallback(
    field => {
      if (!field.id) {
        lfWarn('Field has empty id, will not update', field);
      }
      // do a functional change here, otherwise having a dependency from "form" here
      // it's triggering a cascade re-render to all subviews
      setForm(form => replaceField(form, field));
      setField(field);
      setJsErrors(null);
    },
    [setField, setForm, setJsErrors]
  );

  return (
    <>
      {sidebar === 'field' && field && (
        <FieldEditor
          field={field}
          framework={framework}
          onChange={handleReplaceField}
          locales={form.locales}
          locale={locale}
        />
      )}
      {sidebar === 'field' && !field && (
        <EmptyPlaceholder>
          Hover on a field in the preview of the form and click on "edit" button.
        </EmptyPlaceholder>
      )}
    </>
  );
};

export { EditFieldPanelContainer };
