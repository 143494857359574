import {
  COMMON_PLACEHOLDER,
  COMMON_TRITTICUS_STATUS,
  makeColums,
  RSUITE5_PREFIXES,
  RSUITE5_SIZE,
  ANTD_SIZE,
  ANTD_PREFIXES,
  ANTD_ALLOW_CLEAR,
  ANTD_COUNT_CHARS,
  ANTD_HINT_AS_TOOLTIP,
  ANTD_MAX_LENGTH,
  RSUITE5_HINT_AS_TOOLTIP,
  COMMON_TRITTICUS,
  COMMON_FULL_WIDTH_SIZE,
  MANTINE_SIZE,
  MANTINE_RADIUS,
  MANTINE_VARIANT,
  MANTINE_POINTER,
  MANTINE_ERROR_WITH_STYLE,
  MANTINE_SECTIONS,
  ANTD_VARIANT,
  COMMON_CURRENCIES,
  MUI_SIZE_NO_LARGE,
  MUI_VARIANT,
  MUI_COLORS,
  MUI_FLOATING_LABEL,
  MUI_PREFIX_POSTFIX,
  BOOTSTRAP_SIZE,
  BOOTSTRAP_PREFIXES,
  BOOTSTRAP_FLOATING_LABELS
} from '../costants/index.js';

const manifest = {
  label: 'Currency',
  category: 'general',
  name: 'currency',
  description: 'Formatted currency input box',
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_PLACEHOLDER,
    COMMON_TRITTICUS_STATUS,
    makeColums(
      COMMON_CURRENCIES,
      {
        component: 'select',
        name: 'align',
        label: 'Alignment',
        cleanable: true,
        searchable: false,
        options: [
          { value: 'left', label: 'Left' },
          { value: 'center', label: 'Center' },
          { value: 'right', label: 'Right' },
        ],
        hint: 'Currency alignment in the input field',
        'react-rsuite5': {
          tooltip: true
        }
      }
    )
  ],
  'react': [
    COMMON_FULL_WIDTH_SIZE
  ],
  'react-rsuite5': [
    makeColums(
      RSUITE5_SIZE,
      []
    ),
    COMMON_FULL_WIDTH_SIZE,
    RSUITE5_PREFIXES,
    RSUITE5_HINT_AS_TOOLTIP
  ],
  'react-bootstrap': [
    BOOTSTRAP_SIZE,
    COMMON_FULL_WIDTH_SIZE,
    BOOTSTRAP_PREFIXES,
    BOOTSTRAP_FLOATING_LABELS
  ],
  'react-material-ui': [
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      MUI_SIZE_NO_LARGE,
      {
        ...MUI_VARIANT,
        script: `
        if (variant === 'filled' || variant === 'standard') {
          enable('disableUnderline');
        } else {
          disable('disableUnderline');
        }
        `
      },
      MUI_COLORS
    ),
    makeColums(
      MUI_FLOATING_LABEL,
      {
        name: 'disableUnderline',
        component: 'toggle',
        label: 'Disable underline',
        hint: 'Disable underline in "filled" and "standard" variant',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    MUI_PREFIX_POSTFIX
  ],
  'react-antd': [
    makeColums(
      ANTD_SIZE,
      ANTD_VARIANT
    ),
    COMMON_FULL_WIDTH_SIZE,
    ANTD_PREFIXES,
    makeColums(
      ANTD_COUNT_CHARS,
      ANTD_MAX_LENGTH
    ),
    makeColums(
      ANTD_ALLOW_CLEAR,
      ANTD_HINT_AS_TOOLTIP
    )
  ],
  'react-mantine': [
    makeColums(
      MANTINE_SIZE,
      MANTINE_RADIUS,
      MANTINE_VARIANT
    ),
    COMMON_FULL_WIDTH_SIZE,
    MANTINE_SECTIONS,
    makeColums(
      MANTINE_POINTER,
      MANTINE_ERROR_WITH_STYLE
    )
  ],
  'form-generator': {

  },
  defaultValues: {
    'react-mantine': {
      'react-mantine': {

      }
    },
    'react': {
      'react': {

      }
    },
    'react-bootstrap': {
      'react-bootstrap': {

      }
    },
    'react-rsuite5': {
      'react-rsuite5': {

      }
    },
    'react-material-ui': {
      'react-material-ui': {

      }
    },
    'react-antd': {
      'react-antd': {

      }
    }
  }
};

export { manifest };