import React, { useCallback } from 'react';
import { Button } from 'rsuite';
import { useNavigate } from 'react-router-dom';

import { useModal, WithLogin } from '../../../components';
import { useFormBuilderStore } from '../../../pages/builder/state';
import { useListenTo } from '../../../code-plug';

import { SaveIntoProject } from './save-into-project';



const SaveButtonWithLogin = ({ disabled }) => {
  const navigate = useNavigate();
  const form = useFormBuilderStore(state => state.form);

  const { open: openSaveInProject, close: closeSaveInProject } = useModal({
    view: WithLogin(SaveIntoProject),
    size: 'sm',
    name: 'save_into_project',
    title: 'Save form into project',
    align: 'center',
    labelSubmit: null,
    labelCancel: null
  });

  const handleSave = useCallback(
    async () => {
      const savedForm = await openSaveInProject({ form });
      closeSaveInProject();

      if (savedForm) {
        navigate(`/projects/${savedForm.projectId.id}`);
      }
    },
    [closeSaveInProject, form, navigate, openSaveInProject]
  );

  const attrs = useListenTo({
    saveInProjectWithLogin: () => {
      handleSave();
    }
  });

  return (
    <span {...attrs}>
      <Button
        size="sm"
        appearance="primary"
        onClick={handleSave}
        disabled={disabled}
      >Save form</Button>
    </span>
  );
};

export { SaveButtonWithLogin };
