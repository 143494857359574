import { useCallback, useContext, useDebugValue } from 'react';
import _ from 'lodash';

import { AuthenticationContext } from '../helpers';

const ENABLED_FEATURES = ['dashboard-form-builders'];

const useCurrentUser = ({
  redirectToLogin = false,
  pathname
 } = {}) => {
  const { user, getMe, setCurrentUser } = useContext(AuthenticationContext);
  useDebugValue(user?.email);

  // redirect if not logged
  if (user === null && redirectToLogin) {
    window.location = '/login/?redirect=' + (pathname || window.location.pathname);
  }

  const clearCredentials = useCallback(
    async () => {
      const refreshToken = localStorage.getItem('refresh_token');
      //if (refreshToken) {
        await fetch('https://dashboard.letsform.dev/auth/logout', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json'
          },
          body: refreshToken ? JSON.stringify({
            refresh_token: refreshToken
          }) : undefined
        });
      //}
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('token_expires_at');
      setCurrentUser(null);
    },
    [setCurrentUser]
  );

  return {
    user,
    getMe,
    access_token: localStorage.getItem('access_token'),
    accessToken: localStorage.getItem('access_token'),
    isAnonymous: user === undefined,
    // true/false means authenticated or not, undefined still undecided, it's loading
    isAuthenticated: user != null ? true : (user === null ? false : undefined),
    isRegistered: !_.isEmpty(localStorage.getItem('lf_email')),
    hasFeatureFlag: feature => {
      return (
        ENABLED_FEATURES.includes(feature) ||
        (user != null && Array.isArray(user.featureFlags) && user.featureFlags.includes(feature))
      );
    },
    clearCredentials,
    isPaying: user?.plan?.code !== 'free_plan_dev' && user?.plan?.code !== 'free_plan'
  };
};

export { useCurrentUser };
